@tailwind base;
@tailwind components;
@tailwind utilities;

.curve-underline {
  position: relative;
  text-decoration: none;
}

.curve-underline:after {
  content: "";
  position: absolute;
  width: 100%;
  border-top: solid 3px #03083d;
  left: 0;
  bottom: -10px;
  border-radius: 50%;
  height: 8px;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev::after {
  content: "next";
  color: blue;

  size: 100px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  color: blue;

  line-height: 100;
}

.swiper-pagination-bullet-active {
  background-color: gray;
  z-index: 40;
}
